import Flickity from "flickity";
import App from "../tufts_app";

require("flickity-imagesloaded");

export class Carousel {
  constructor(element, elements = {}) {
    this.setElements(element, elements);

    // FLICKITY
    this.initializeFlickity();

    // STATE
    this.state = this.defaultState;

    // EVENTS
    this.setUpEvents();
  }

  get defaultState() {
    return {
      index: 0,
      maxIndex: this.flickity.slides.length - 1,
    };
  }

  setElements(element, elements) {
    this.element = element;
    this.carouselWrapper =
      elements.carouselWrapper ||
      element.querySelector(".js-carousel__flickity");
    this.previousControls =
      elements.previousControl ||
      element.querySelectorAll(".js-carousel__previous");
    this.nextControls =
      elements.nextControl || element.querySelectorAll(".js-carousel__next");
    this.caption =
      elements.caption || element.querySelector(".js-carousel__caption");
    this.count = elements.count || element.querySelector(".js-carousel__count");
  }

  initializeFlickity() {
    this.flickity = new Flickity(this.carouselWrapper, this.defaultConfig);
  }

  setUpEvents() {
    if (this.nextControls) {
      this.nextControls.forEach((control) => {
        control.addEventListener("click", () => {
          this.next();
        });
      });
    }

    if (this.previousControls) {
      this.previousControls.forEach((control) => {
        control.addEventListener("click", () => {
          this.previous();
        });
      });
    }
  }

  update(update) {
    this.state = Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("index")) {
      this.renderCaption();
      this.renderCount();
    }
  }

  renderCaption() {
    this.caption.innerHTML = this.currentSlide.querySelector(
      ".js-carousel__slide-caption"
    ).innerHTML;
  }

  renderCount() {
    this.count.innerHTML = `${this.state.index + 1}/${
      this.flickity.slides.length
    }`;
  }

  next() {
    this.flickity.next();
    this.updateIndex();
  }

  previous() {
    this.flickity.previous();
    this.updateIndex();
  }

  updateIndex() {
    const index = this.flickity.selectedIndex;
    this.update({ index });
  }

  /* eslint-disable class-methods-use-this */
  get defaultConfig() {
    return {
      wrapAround: true,
      prevNextButtons: false,
      pageDots: true,
      imagesLoaded: true,
    };
  }

  get currentSlide() {
    return this.flickity.slides[this.state.index].cells[0].element;
  }
}

export const init = () => {
  App.addEventListener("pageLoad", () => {
    [...document.querySelectorAll(".js-carousel")].forEach((instance) => {
      return new Carousel(instance);
    });
  });
};
