import App from "../tufts_app";

class Accordion {
  constructor(ele) {
    this.wrapper = ele;
    this.content = this.wrapper.querySelector(".js-accordion-content");

    this.content.addEventListener("click", (e) => {
      e.stopPropagation();
    });

    this.wrapper.addEventListener("click", () => {
      if (this.wrapper.classList.contains("collapse")) {
        this.wrapper.classList.remove("collapse");
      } else {
        this.wrapper.classList.add("collapse");
      }
    });
  }
}

export const init = () => {
  App.addEventListener("pageLoad", () => {
    [...document.querySelectorAll(".js-accordion")].map((ele) => {
      return new Accordion(ele);
    });
  });
};
