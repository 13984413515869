import App from "../tufts_app";

class Marquee {
  constructor(ele) {
    this.element = ele;
    this.innerWrapper = this.element.querySelector(".js-marquee-inner");
    this.textWrapper = this.innerWrapper.querySelector(
      ".js-marquee-text-wrapper"
    );
    this.inputText = this.textWrapper.querySelectorAll(".js-marquee-text");

    /*
      --------------
      INITIAL RENDER
      --------------
    */

    let textWidth = this.textWrapper.offsetWidth;
    while (textWidth <= this.innerWrapper.offsetWidth) {
      this.inputText.forEach((text) => {
        // clone all of the text nodes and add them until desired width is achieved
        const textClone = text.cloneNode(true);
        this.textWrapper.append(textClone);
      });
      textWidth = this.textWrapper.offsetWidth;
    }

    // we need two textWrappers one after the other to make an infinite scrolling animations
    this.textWrapperClone = this.textWrapper.cloneNode(true);
    this.innerWrapper.append(this.textWrapperClone);

    this.element.classList.add("marquee--running");
  }
}

export const init = () => {
  App.addEventListener("pageLoad", () => {
    [...document.querySelectorAll(".js-marquee")].map((ele) => {
      return new Marquee(ele);
    });
  });
};
