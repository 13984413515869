import App from "../tufts_app";

class TextReveal {
  constructor(ele) {
    this.wrapper = ele;
    this.button;

    this.addButton();

    this.wrapper.addEventListener("click", () => {
      if (this.wrapper.classList.contains("collapse")) {
        this.wrapper.classList.remove("collapse");
        this.wrapper.removeChild(this.button);
      }
    });
  }

  addButton() {
    this.button = document.createElement("button");
    this.button.classList.add("text-reveal-button");
    this.button.setAttribute("aria-label", "Reveal more");
    this.button.innerHTML = `
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.27261 0.314697H8.22937V6.3147H6.27261V0.314697ZM6.27261 8.3147H0.402344V6.3147H6.27261L6.27261 8.3147ZM6.27261 8.3147H8.22937V14.3147H6.27261V8.3147ZM8.22937 8.3147L8.22937 6.3147H14.0996V8.3147H8.22937Z" />
      </svg>
    `;
    this.wrapper.appendChild(this.button);
  }
}

export const init = () => {
  App.addEventListener("pageLoad", () => {
    [...document.querySelectorAll(".js-text-reveal")].map((ele) => {
      return new TextReveal(ele);
    });
  });
};
