import App from "../tufts_app";

class MobileSearchForm {
  constructor(ele) {
    this.wrapper = ele;
    this.icon = this.wrapper.querySelector(".js-mobile-search-icon");
    this.input = this.wrapper.querySelector("input");
    this.form = this.wrapper.querySelector("form");

    this.wrapper.addEventListener("click", (e) => {
      e.stopPropagation();
    });

    this.icon.addEventListener("click", (e) => {
      e.stopPropagation();

      if (!this.wrapper.classList.contains("active")) {
        this.wrapper.classList.add("active");
        this.input.focus();
      } else {
        this.form.submit();
      }
    });
  }
}

export const init = () => {
  App.addEventListener("pageLoad", () => {
    [...document.querySelectorAll(".js-mobile-search-form")].map((ele) => {
      return new MobileSearchForm(ele);
    });
  });
};
