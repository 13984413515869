class ProgramGrid {
  constructor(ele) {
    this.ele = ele;
    this.showMoreBtn = this.ele.querySelector(".js-program-grid-show-more");
    this.showMoreBtnMobile = this.ele.querySelector(
      ".js-program-grid-show-more-mobile"
    );
    this.showLessBtn = this.ele.querySelector(".js-program-grid-show-less");
    this.hiddenCards = this.ele.querySelectorAll(".js-program-card-hidden");
    this.hiddenCardsMobile = this.ele.querySelectorAll(
      ".js-program-grid-card-desktop"
    );

    if (this.showMoreBtn) {
      this.showMoreBtn.addEventListener("click", () => {
        this.hiddenCards.forEach((card) => card.classList.remove("hidden"));
        this.showMoreBtn.classList.add("hidden");
        this.showLessBtn.classList.remove("hidden");
      });

      this.showLessBtn.addEventListener("click", () => {
        this.hiddenCards.forEach((card) => card.classList.add("hidden"));
        this.showLessBtn.classList.add("hidden");
        this.showMoreBtn.classList.remove("hidden");
      });
    }

    if (this.showMoreBtnMobile) {
      this.showMoreBtnMobile.addEventListener("click", () => {
        this.hiddenCardsMobile.forEach((card) => card.classList.add("show"));
        this.showMoreBtnMobile.classList.add("hidden");
      });
    }
  }
}

export const init = () => {
  document.addEventListener("turbolinks:load", () => {
    [...document.querySelectorAll(".js-program-grid")].map(
      (ele) => new ProgramGrid(ele)
    );
  });
};
