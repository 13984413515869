export const getFilepickerUrl = (baseUrl, transformations) => {
  const pathParts = baseUrl.split("/");
  const imgID = pathParts.pop();
  const transformationsEncoded =
    typeof transformations === "string"
      ? transformations
      : transformations.join("/");
  const urlPath = pathParts.concat(transformationsEncoded, imgID).join("/");
  return urlPath.toString();
};

export const loadImage = (src, next) => {
  const img = new Image();
  img.onload = next(img);
  img.src = src;
};
