class CollectionSearch {
  constructor(ele) {
    this.ele = ele;
    this.input = this.ele.querySelector("input[type='text']");
    this.form = this.ele.querySelector(".js-collection-search-form");
    this.arrow = this.ele.querySelector(".js-collection-search-arrow");

    this.ele.addEventListener("click", () => {
      this.input.placeholder = "";
    });

    this.arrow.addEventListener("click", () => {
      this.form.submit();
    });
  }
}

export const init = () => {
  document.addEventListener("turbolinks:load", () => {
    [...document.querySelectorAll(".js-collection-search")].map(
      (ele) => new CollectionSearch(ele)
    );
  });
};
