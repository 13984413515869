class SiteHeader {
  constructor(ele) {
    this.ele = ele;

    this.ele.addEventListener("click", () => {
      if (this.ele.classList.contains("active")) {
        this.ele.classList.remove("active");
      } else {
        this.ele.classList.add("active");
      }
    });
  }
}

export const init = () => {
  document.addEventListener("turbolinks:load", () => {
    [...document.querySelectorAll(".js-site-header")].map(
      (ele) => new SiteHeader(ele)
    );
  });
};
