/* JavaScript for HomepageGridModule */

import * as Util from "../utilities";
import { HappeningTodayBadge } from "../scripts/happening_today_badge";

// Export the class itself
export class HomepageGridModule {
  constructor(element) {
    // elements
    this.element = element;
    this.cards = this.element.querySelectorAll(".js-hgm-card");
    this.imagePlaceholder = this.element.querySelector(
      ".js-hgm-image-placeholder"
    );
    this.imageClone = this.imagePlaceholder.querySelector(
      ".js-hgm-image-clone"
    );
    this.interval = null;
    this.hoverTime = 500;
    this.zoomedImageWidth = 1280;
    this.zIndexCounter = 0;

    this.state = {
      nodeToExpand: null,
      imageExpanded: false,
      imageToExpand: null,
      shouldExpand: this.cards.length > 1,
    };

    // initial render
    this.cards.forEach((item) => {
      const card = item;
      card.style.zIndex = 0;

      if (card.querySelector(".js-happening-today")) {
        const badge = new HappeningTodayBadge(card);
        badge.appendToCard();
      }
    });
    this.render(this.state);

    // event listeners
    this.mouseEnterHandler = this.handleMouseEnter.bind(this);
    this.mouseLeaveHandler = this.handleMouseLeave.bind(this);

    this.cards.forEach((item) => {
      item.addEventListener("mouseenter", this.mouseEnterHandler);
      item.addEventListener("mouseleave", this.mouseLeaveHandler);
    });
  }

  handleMouseEnter(e) {
    this.update({ nodeToExpand: e.target });
  }

  handleMouseLeave() {
    this.update({ nodeToExpand: null });
  }

  startTimer() {
    this.imageHandler = this.expandImage.bind(this);
    this.timer = setTimeout(this.imageHandler, this.hoverTime);
  }

  stopTimer() {
    clearTimeout(this.timer);
  }

  expandImage() {
    this.update({ imageExpanded: true });
  }

  resetImage() {
    this.update({ imageExpanded: false });
    clearInterval(this.interval);
    if (this.imagePlaceholder) {
      this.imagePlaceholder.style.zIndex = -1;
      this.imageClone.style.transition = "opacity 0s";
      this.imageClone.style.opacity = 0;
      this.imageClone.style.visibility = "hidden";
      this.imageClone.innerHTML = "";
    }

    if (this.state.imageToExpand) {
      this.state.imageToExpand.style.transition = "opacity 0s";
      this.state.imageToExpand.style.opacity = 1;
      this.state.imageToExpand.style.visibility = "visible";
      this.state.imageToExpand = null;
    }
  }

  // State management functions

  update(update) {
    this.state = Object.assign(this.state, update);
    this.render(update);
  }

  render(update) {
    if (update.hasOwnProperty("nodeToExpand")) {
      if (!this.state.nodeToExpand && this.state.shouldExpand) {
        this.stopTimer();
        this.resetImage();
      } else if (this.state.nodeToExpand && this.state.shouldExpand) {
        this.startTimer();
        this.bringNodeToFront();
      }
    }
    if (update.hasOwnProperty("imageExpanded")) {
      this.renderExpandedImage();
    }
  }

  bringNodeToFront() {
    setTimeout(() => {
      this.zIndexCounter += 1;

      if (this.state.nodeToExpand) {
        this.state.nodeToExpand.style.zIndex = this.zIndexCounter;
      }
    }, 150);
  }

  getZoomedImgSrc(baseUrl) {
    // If using default background image, do not do all this filepicker stuff
    if (baseUrl.includes("filestack") === false) return baseUrl;

    const resizeTransformation = `resize=width:${this.zoomedImageWidth},fit:max`;
    const formatTransformation = "output=f:jpg,background:white";
    const transformationString = [
      resizeTransformation,
      formatTransformation,
    ].join("/");
    return Util.getFilepickerUrl(baseUrl, transformationString);
  }

  // animateExpandingImage(left, top, width, height, wrapperWidth, wrapperHeight) {
  //   let [leftPos, topPos, eleWidth, eleHeight] = [left, top, width, height];
  //
  //   // TODO: Figure out this math!!
  //   const leftExpansionRate = wrapperWidth / left;
  //   const topExpansionRate = wrapperHeight / top;
  //   const verticalExpansionRate = 100 / (100 - height);
  //   const horizontalExpansionRate = 100 / (100 - width);
  //
  //   const expand = () => {
  //     if (leftPos <= 0 && topPos <= 0 && eleWidth >= 100 && eleHeight >= 100) {
  //       clearInterval(this.interval);
  //     }
  //     if (leftPos > 0) {
  //       leftPos -= leftExpansionRate;
  //     }
  //
  //     if (topPos > 0) {
  //       topPos -= topExpansionRate;
  //     }
  //
  //     if (eleWidth < 100) {
  //       eleWidth += horizontalExpansionRate;
  //     }
  //
  //     if (eleHeight < 100) {
  //       eleHeight += verticalExpansionRate;
  //     }
  //
  //     this.imageClone.style.width = `${eleWidth}%`;
  //     this.imageClone.style.height = `${eleHeight}%`;
  //     this.imageClone.style.left = `${leftPos}px`;
  //     this.imageClone.style.top = `${topPos}px`;
  //   };
  //
  //   this.interval = setInterval(expand, 10); // draw every 10ms
  // }

  renderExpandedImage() {
    if (!this.state.imageExpanded) {
      return;
    }

    // NOTE: This code is an effort to implement expanding images. Leaving it here for now

    // set elements
    // this.state.imageToExpand =
    //   this.state.nodeToExpand.querySelector(".js-hgm-image");
    // let imageRect = this.state.imageToExpand.getBoundingClientRect();
    // let wrapperRect = this.imagePlaceholder.getBoundingClientRect();

    // images left and top positions inside wrapper

    // let imageCloneWidth = (imageRect.width / wrapperRect.width) * 100;
    // let imageCloneHeight = (imageRect.height / wrapperRect.height) * 100;
    //
    // let imagePosLeft = imageRect.left - wrapperRect.left;
    // let imagePosTop = imageRect.top - wrapperRect.top;

    // this.imageClone.style.width = `${imageCloneWidth}%`;
    // this.imageClone.style.height = `${imageCloneHeight}%`;
    // this.imageClone.style.left = `${imagePosLeft}px`;
    // this.imageClone.style.top = `${imagePosTop}px`;
    // this.imageClone.style.transition = "width 1s, height 1s";

    // this.imageClone.style.width = "100%";
    // this.imageClone.style.height = "100%";
    // this.imageClone.style.left = "0";
    // this.imageClone.style.top = "0";
    // this.imageClone.style.opacity = 0;
    // this.imageClone.style.visibility = "hidden";

    // CLONES IMAGE AND PLACES IN WRAPPER
    const baseUrl = this.state.nodeToExpand.dataset.baseImageUrl;
    const imageSrc = this.getZoomedImgSrc(baseUrl);

    Util.loadImage(imageSrc, (img) => {
      this.imageClone.append(img);
      this.imagePlaceholder.style.zIndex = this.zIndexCounter;
      this.imageClone.style.transition = "opacity 1s";

      setTimeout(() => {
        this.imageClone.style.opacity = 1;
        this.imageClone.style.visibility = "visible";
      }, 250);

      // TODO: evenly expand image to fill the image placeholder div

      // this.animateExpandingImage(
      //   imagePosLeft,
      //   imagePosTop,
      //   imageCloneWidth,
      //   imageCloneHeight,
      //   wrapperRect.width,
      //   wrapperRect.height
      // );
    });
  }
}

// Exports an array of all the current instances
export const homepageGridModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the HomepageGridModule on any given page
  document.addEventListener("turbolinks:load", () => {
    homepageGridModules.current = [
      ...document.querySelectorAll(".js-homepage-grid-module"),
    ].map((instance) => new HomepageGridModule(instance));
  });
};
