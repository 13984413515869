import sample from "lodash/sample";

export class HappeningTodayBadge {
  constructor(ele) {
    this.card = ele.querySelector(".hgm__card-inner");

    this.properties = sample([
      { x: "60%", y: "46%", rotation: "-13deg", color: "#C0F550" },
      { x: "69%", y: "39%", rotation: "36.89deg", color: "#4ADBDB" },
      { x: "15%", y: "85%", rotation: "-28deg", color: "#A290FF" },
      { x: "15%", y: "15%", rotation: "17deg", color: "#BA700C" },
    ]);

    this.badge = document.createElement("div");
    this.badge.innerHTML = "Happening today!";
    this.badge.classList.add("happening-today-badge");
    this.badge.style.backgroundColor = this.properties.color;
    this.badge.style.transform = `rotate(${this.properties.rotation})`;
    this.badge.style.left = this.properties.x;
    this.badge.style.top = this.properties.y;

    this.card.append(this.badge);
  }

  appendToCard() {
    this.card.append(this.badge);
  }
}
